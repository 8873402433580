import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import theme from "../../../theme";
import { getUser } from "src/services/auth";
import { userRoles } from "../../../lib/constants";
import firebase from "src/firebase";
import { getCurrentUserAdminType } from "../../../services/auth";
import PropTypes from "prop-types";

const styles = {
  labelBox: {
    display: "flex",
    marginBottom: "20px",
  },
  label: {
    padding: "2px 15px",
    margin: "2px",
  },
  labelGreen: {
    background: theme.palette.primary.main,
    color: theme.palette.black.darkSeaBlack,
  },
  labelPurple: {
    background: theme.palette.secondary.main,
    color: theme.palette.grey.white,
  },
  labelBlue: {
    background: theme.palette.adminBlue.main,
    color: theme.palette.grey.white,
  },
};
const backgroundColor = (user) => {
  if (user === userRoles.pilot || user === "Drone operator") {
    return styles.labelPurple;
  } else if (
    user === userRoles.client ||
    user === "Client page" ||
    user == "Enterprise account"
  ) {
    return styles.labelGreen;
  } else {
    return styles.labelBlue;
  }
};

const UserRoleTag = ({ userType = undefined }) => {
  const [adminType, setAdminType] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const currentUser = getUser("user");

  useEffect(() => {
    let cancel = false;
    if (currentUser.role == userRoles.admin) {
      getCurrentUserAdminType(firebase).then((data) => {
        if (cancel) return;
        setAdminType(data);
      });
    }
    return () => {
      cancel = true;
    };
  }, [currentUser.role]);

  useEffect(() => {
    if (userType) {
      setUserRole(userType);
    } else {
      setUserRole(currentUser.role);
    }
  }, [currentUser.role, userType]);

  return (
    <Box sx={styles.labelBox}>
      <Typography
        variant="body3"
        sx={{ ...styles.label, ...backgroundColor(userRole) }}
      >
        {userType
          ? userType
          : userRole == userRoles.pilot
          ? "Drone operator"
          : adminType}
      </Typography>
    </Box>
  );
};

UserRoleTag.propTypes = {
  userType: PropTypes.string,
};
// UserRoleTag.defaultProps = {
//   userType: undefined,
// };

export default UserRoleTag;
